const state = () => ({
    locations: [
        {
            title: 'Rotterdam',
            email: 'hallo@23g.nl',
            phone: {
                formatted: '+31 (0) 10 412 66 62',
                number: '+31104126662',
            },
            addressLine1: 'Kratonkade 7',
            addressLine2: '3024 ES Rotterdam',
            image: '/images/locations/rotterdam.webp',
        },
        {
            title: 'Goes',
            email: 'hallo@23g.nl',
            phone: {
                formatted: '+31 (0) 10 412 66 62',
                number: '+31104126662',
            },
            addressLine1: 'Eberhardtweg 3F',
            addressLine2: '4462 HH Goes',
            image: '/images/locations/goes.webp',
        },
        {
            title: 'New York',
            email: 'hello@23g.nyc',
            phone: {
                formatted: '+1 172 523 927 55',
                number: '+117252392755',
            },
            addressLine1: '205 E 42nd St',
            addressLine2: 'New York NY 10017',
            image: '/images/locations/new-york.webp',
        },
        {
            title: 'Las Vegas',
            email: 'hello@23g.vegas',
            phone: {
                formatted: '+1 172 523 927 55',
                number: '+117252392755',
            },
            addressLine1: '10845 Griffith Peak Dr #2',
            addressLine2: 'Las Vegas NV 89135 (Summerlin)',
            image: '/images/locations/las-vegas.webp',
        },
    ],
});

export default {
    state,
};
